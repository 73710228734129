import React from "react"

import { PageType } from "../index"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo"

const Datenschutz: PageType = ({ location }) => {
    return (
        <Layout location={location}>
            <Seo
                title="Datenschutz - Lindsey and friends"
                description=""
            />
        </Layout>
    )
}

export default Datenschutz
